<div class="faq-component" fxLayout="row" fxLayoutAlign="center">
  <div
    class="container"
    fxLayout="column"
    fxFlex.xs="100"
    fxFlex.sm="540px"
    fxFlex.md="720px"
    fxFlex.lg="960px"
    fxFlex.xl="1140px"
  >
    <h1 class="mat-display-2">Frequently Asked Questions</h1>
    <h2 class="mat-display-1">1: Wo sind die Weine physisch gelagert?</h2>
    <p class="mat-body-1">
      Die Weinfächer befinden sich auf dem Gelände der Weinkellerei Stämpfli AG
      in Laupen BE. Die Adresse für den Versand und die Anlieferung von Wein
      lautet wie folgt:
    </p>
    <p class="mat-body-1">
      Weinlager Laupen<br />
      Fach Nr. XY<br />
      c/o Weinkellerei Stämpfli AG<br />
      Bösingenstrasse 28A<br />
      CH - 3177 Laupen
    </p>
    <h2 class="mat-display-1">2. Ist das Weinlager immer zugänglich?</h2>
    <p class="mat-body-1">
      Sobald Sie im Besitz eines Schlüssels sind, können Sie das Weinlager sowie
      Ihr Fach während 24h Stunden an 365 Tagen betreten.
    </p>
    <h2 class="mat-display-1">3. Was kostet ein Fach?</h2>
    <p class="mat-body-1">
      Fächer gibt es ab CHF 180.00 / Jahr (bis 90 Flaschen) bis CHF 950.00 /
      Jahr (ca. 1'600 Flaschen).
    </p>
    <p class="mat-body-1">
      Die detaillierte Preisliste finden Sie hier:
      <a href="https://weinlagerlaupen.ch/wine-boxes"
        >https://weinlagerlaupen.ch/wine-boxes</a
      >.<br />
      Und hier:
      <a href="../assets/pdf/2_onboarding_preise.pdf"
        >https://weinlagerlaupen.ch/downloads</a
      >.
    </p>
    <h2 class="mat-display-1">
      4. Kann ich auch andere Gegenstände als Wein einlagern?
    </h2>
    <p class="mat-body-1">
      Die Mietbedingungen sind genau geregelt. Grundsätzlich sind nur Weine und
      Spirituosen zum Einlagern erlaubt. Die Regeln finden Sie hier:
      <a href="https://weinlagerlaupen.ch/downloads"
        >https://weinlagerlaupen.ch/downloads</a
      >.
    </p>
    <h2 class="mat-display-1">5. Sind die Weine versichert?</h2>
    <p class="mat-body-1">
      Für die bei uns eingelagerten Weine übernehmen wir keine Haftung. Wir
      verfügen jedoch über sehr attraktive Konditionen, die Sie
      <a href="https://weinlagerlaupen.ch/downloads">hier</a> einsehen können.
    </p>
    <h2 class="mat-display-1">
      6. Welche Temperaturen und welche Feuchtigkeit herrschen im Weinlager?
    </h2>
    <p class="mat-body-1">
      Ständige und konstante Temperatur: 14.3-15.3 Grad Celsius.<br />
      Ständige Luftfeuchtigkeit: 70%-85% Luftfeuchtigkeit.
    </p>
    <h2 class="mat-display-1">7. Wer betreibt das Weinlager?</h2>
    <p class="mat-body-1">
      Das Weinlager wird durch die Firma Weinkellerei Stämpfli AG, CH-3177
      Laupen BE betrieben.
    </p>
    <h2 class="mat-display-1">8. Welche Vorteile gibt es sonst noch?</h2>
    <ul>
      <li><p class="mat-body-1">Degustationen und Anlässe für Mieter</p></li>
      <li>
        <p class="mat-body-1">
          Kostenlose Benutzung des Degustationsraumes, 1x im Jahr für Mieter
          inklusive
        </p>
      </li>
      <li><p class="mat-body-1">Kostenlose App mit einigen Features</p></li>
      <li>
        <p class="mat-body-1">
          Next-Day-Delivery vom und zum Weinfach, via App
        </p>
      </li>
    </ul>
    <h2 class="mat-display-1">
      9. Bieten Sie auch Portfoliomanagement und Tipps für Wein-Investments an?
    </h2>
    <p class="mat-body-1">
      Wir bieten eine Komplettlösung für Anleger an. Von der Beschaffung zur
      Bewertung, Lagerung und dem anschliessenden Verkauf. Auf Wunsch tracken
      wir Ihre Sammlung und kümmern uns um die fachgerechte Lagerung und
      Logistik. Nehmen Sie unverbindlich mit uns Kontakt auf, wir beraten Sie
      gerne.
    </p>
    <h2 class="mat-display-1">
      10. Können Weine direkt ins Fach geliefert werden?
    </h2>
    <p class="mat-body-1">
      Via App können Sie Weine direkt in Ihr Fach liefern lassen. Auch eine
      Auslagerung, also von Ihrem Fach an eine spezifische Adresse, ist Next-Day
      bis 12h00 garantiert möglich. Dieser Service (Ein- und Auslagern) kostet
      CHF15.00 pro Bewegung für den Versand/Empfang aus und in die ganze
      Schweiz.
    </p>
    <h2 class="mat-display-1">
      11. Wie vielen Personen bietet das Carnotzet Platz?
    </h2>
    <p class="mat-body-1">
      Das Carnotzet ist ein Raum für Degustationen und kleinere Gesellschaften.
      Am grossen Tisch in der Mitte finden bis zu 14 Personen Platz, stehend
      bietet der Raum Platz für bis zu 25 Personen.
    </p>
    <h2 class="mat-display-1">
      12. Welche Utensilien und Mobiliar befinden sich im Carnotzet? Was muss
      ich als Veranstalter mitbringen?
    </h2>
    <p class="mat-body-1">
      Im Carnotzet befinden sich Utensilien und Mobiliar für den Genuss von
      Wein: Weingläser, Mineralwassergläser und Karaffen stehen für Sie zur
      freien Nutzung bereit. Ein separater Raum für die Zubereitung von Speisen
      sowie Toiletten stehen ebenfalls zur Verfügung.
    </p>
    <h2 class="mat-display-1">
      13. Dürfen auch externe Interessenten das Carnotzet benutzen?
    </h2>
    <p class="mat-body-1">
      Auch Aussenstehende dürfen den Raum benutzen. Das Reglement sowie den
      Mietvertrag finden Sie hier:
      <a href="https://weinlagerlaupen.ch/event-room"
        >https://weinlagerlaupen.ch/event-room</a
      >.
    </p>
    <h2 class="mat-display-1">
      Haben wir Ihre Fragen beantwortet? Falls nicht, zögern Sie bitte nicht,
      uns zu kontaktieren
      <a href="mailto:info@weinlagerlaupen.ch">info@weinlagerlaupen.ch</a>.
    </h2>
    <br />
  </div>
</div>
<app-footer></app-footer>
